const { postRequest } = require("./http-helper");
const jwt = require("jsonwebtoken");

const logIn = ({ email, password }, administrator) => {
  return new Promise((resolve, reject) => {
    email = email.toLowerCase();
    postRequest(
      administrator ? "administrators/signIn" : "sellers/signIn",
      { email, password },
      resolve,
      reject,
      null,
      saveToken
    );
  });
};

const sendResetPasswordCode = ({ email }, administrator) => {
  return new Promise((resolve, reject) => {
    email = email.toLowerCase();
    postRequest(
      administrator
        ? "administrators/sendResetPasswordCode"
        : "sellers/sendResetPasswordCode",
      { email },
      resolve,
      reject,
      null,
      ""
    );
  });
};

const resetPassword = ({ email, newPassword, token }, administrator) => {
  return new Promise((resolve, reject) => {
    email = email.toLowerCase();
    postRequest(
      administrator ? "administrators/resetPassword" : "sellers/resetPassword",
      { email, newPassword, token },
      resolve,
      reject,
      null,
      ""
    );
  });
};

const logOut = () => {
  localStorage.clear();
  window.location.reload();
};

const isAuthenticated = () => {
  try {
    const token = localStorage.getItem("ECO_EMPAQUES_STUFF");
    if (!token) {
      return false;
    }
    const decodedToken = jwt.decode(token, { complete: true });
    if (!decodedToken) {
      localStorage.clear();
      return false;
    }
    return decodedToken;
  } catch (error) {
    console.log(error);
    localStorage.clear();
    return false;
  }
};

const isAuthenticatedAdministrator = () => {
  const token = isAuthenticated();
  if (!token) {
    return false;
  }

  return token.payload.administrator ? token : false;
};

const saveToken = (token, resolve) => {
  localStorage.clear();
  localStorage.setItem("ECO_EMPAQUES_STUFF", token);
  resolve("");
};

module.exports = {
  logIn,
  logOut,
  isAuthenticated,
  resetPassword,
  sendResetPasswordCode,
  isAuthenticatedAdministrator,
};
