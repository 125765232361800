import { lazy } from "react-router-guard";
import { noAuth, auth, adminAuth } from "./guards/guards";

export default [
  {
    path: "/logIn",
    canActivate: [noAuth],
    component: lazy(() => import("./containers/Login/Login"))
  },
  {
    path: "/administratorLogIn",
    canActivate: [noAuth],
    component: lazy(() => import("./containers/Login/Login"))
  },
  {
    path: "/resetPassword",
    canActivate: [noAuth],
    component: lazy(() => import("./containers/ResetPassword/ResetPassword"))
  },
  {
    path: "/administratorResetPassword",
    canActivate: [noAuth],
    component: lazy(() => import("./containers/ResetPassword/ResetPassword"))
  },
  {
    path: "",
    canActivate: [auth],
    component: lazy(() => import("./layouts/MainLayout/MainLayout")),
    routes: [
      {
        path: "/balance",
        exact: true,
        canActivate: [adminAuth],
        component: lazy(() => import("./containers/Balance/Balance"))
      },
      {
        path: "/clients",
        exact: true,
        component: lazy(() => import("./containers/Clients/Clients"))
      },
      {
        path: "/clients/:id/order",
        exact: true,
        component: lazy(() => import("./containers/Clients/ClientOrder/ClientOrder"))
      },
      {
        path: "/inventory",
        exact: true,
        component: lazy(() => import("./containers/Inventory/Inventory"))
      },
      {
        path: "/orders",
        exact: true,
        component: lazy(() => import("./containers/Orders/Orders"))
      },
      {
        path: "/orders/create",
        exact: true,
        component: lazy(() => import("./containers/OrderForm/OrderForm"))
      },
      {
        path: "/orders/details/:id/edit",
        exact: true,
        component: lazy(() => import("./containers/OrderForm/OrderForm"))
      },
      {
        path: "/orders/details/:id",
        exact: true,
        component: lazy(() => import("./containers/OrderDetails/OrderDetails"))
      },
      {
        path: "/personal",
        exact: true,
        canActivate: [adminAuth],
        component: lazy(() => import("./containers/Sellers/Sellers"))
      },
      {
        path: "/personal/manageAdmin",
        exact: true,
        canActivate: [adminAuth],
        component: lazy(() => import("./containers/ManageAdmin/ManageAdmin"))
      },
      {
        path: "/suppliers",
        exact: true,
        canActivate: [adminAuth],
        component: lazy(() => import("./containers/Suppliers/Suppliers"))
      },
      {
        path: "/suppliers/:id/purchases",
        exact: true,
        canActivate: [adminAuth],
        component: lazy(() => import("./containers/Suppliers/SupplierPurchases/SupplierPurchase"))
      },
      {
        path: "/productInvoices/details/:id/edit",
        component: lazy(() => import("./containers/ProductsInvoice/ProductsInvoiceForm/ProductsInvoiceForm"))
      },
      {
        path: "/productInvoices",
        component: lazy(() => import("./containers/ProductsInvoice/ProductInvoice"))
      },
      {
        path: "/productsInvoice/create",
        component: lazy(() => import("./containers/ProductsInvoice/ProductsInvoiceForm/ProductsInvoiceForm"))
      },
      {
        path: "",
        exact: true,
        component: lazy(() => import("./containers/Dashboard/Dashboard"))
      }
    ]
  },
  {
    path: "**",
    redirect: ""
  }
];
